<template>
  <centered-layout>
    <div class="not-found">
      <div class="not-found__error">
        Error!
      </div>

      <div class="not-found__404" data-text="404">
        404
      </div>

      <div class="not-found__text">
        Not Found
      </div>

      <div class="not-found">
        <div class="not-found__msg disabled">
          Oops! The <span style="color: black">{{ resource }}</span> you were
          looking for was not found!
        </div>

        <div @click="goBack" class="not-found__back">
          Take me home
        </div>
      </div>
    </div>
  </centered-layout>
</template>

<script>
  import CenteredLayout from "@/components/layouts/CenteredLayout";

  export default {
    name: "NotFound",

    props: {
      resource: { type: String, default: "page" },
    },

    components: {
      CenteredLayout,
    },

    methods: {
      goBack() {
        this.$router.push({ name: "Home" });
      },
    },
  };
</script>

<style lang="scss" scoped>
  @import "@/assets/scss/main.scss";

  .not-found {
    padding: 0 1rem;
    text-align: center;

    &__error,
    &__text {
      font-size: 1.5rem;
      font-weight: 600;
      letter-spacing: 2px;

      text-transform: uppercase;
    }

    &__404 {
      font-size: 10rem;
      line-height: 10rem;
      font-weight: 600;
      letter-spacing: 2px;

      @include textGlitch(
        "not-found__404",
        17,
        black,
        white,
        red,
        skyblue,
        450,
        115
      );
    }

    &__msg {
      margin-top: 2rem;
      font-size: 1.75rem;
    }

    &__back {
      margin-top: 1rem;
      padding: 0.5rem;
      color: $primary-color;

      font-weight: 600;
      font-size: 1.25rem;
      text-transform: uppercase;

      cursor: pointer;
    }
  }
</style>
